import get from 'lodash.get';

export const video = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);
  const fieldVideoItem = get(data, 'fieldVideoItem', undefined);

  return {
    type: get(data, 'entityBundle', ''),
    poster: {
      url: fieldVideoItem
        ? get(fieldVideoItem, 'entity.fieldPoster.url', '')
        : get(data, 'fieldPoster.url', ''),
      alt: fieldVideoItem
        ? get(fieldVideoItem, 'entity.fieldPoster.alt', '')
        : get(data, 'fieldPoster.alt', ''),
      title: fieldVideoItem
        ? get(fieldVideoItem, 'entity.fieldPoster.title', '')
        : get(data, 'fieldPoster.title', ''),
      webp: fieldVideoItem
        ? get(fieldVideoItem, 'entity.fieldPoster.derivative.url', '')
        : get(data, 'fieldPoster.derivative.url', ''),
    },
    videoUrl: fieldVideoItem
      ? get(fieldVideoItem, 'entity.fieldVideoUrl.url.path', '')
      : get(data, 'fieldVideoUrl.url.path', ''),
    headline: get(data, 'fieldHeadline.processed', undefined),
    description: get(data, 'fieldCopy.processed', undefined),
    background: get(data, 'fieldBackground[0]', 'no') === 'yes',
    video: fieldVideoItem
      ? get(fieldVideoItem, 'entity.fieldVideo.entity.url', '')
      : get(data, 'fieldVideo.entity.url', ''),
    videoVariant: get(fieldVideoItem, 'entity.fieldVariantVideoText', 'file'),
    schemaData: get(data, 'fieldName', undefined)
      ? {
          name: get(data, 'fieldName', ''),
          duration: get(data, 'fieldVideoDuration', ''),
          description: get(data, 'fieldVideoDescription', ''),
          uploadDate: get(data, 'entityCreated', ''),
        }
      : undefined,
  };
};
