import React from 'react';

// === Components === //
import Helmet from 'react-helmet';
import Layout from '@components/layout';
import PageComponents from '@components/layout/PageComponents/PageComponents';

// === Content === //
import { HowItWorksSiteDTO } from '@DTO/HowItWorksSiteDTO';

// === Helpers === //
import { breadcrumbsObject, organizationObject } from '@helpers/schemaOrg';

// === Types === //
import { TemplatePageContextData } from '@type/index.d';

interface HowItWorksSiteProps {
  pageContext?: TemplatePageContextData;
}

const HowItWorksSite = ({ pageContext }: HowItWorksSiteProps) => {
  const howItWorksSite = HowItWorksSiteDTO(pageContext?.uuid);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbsObject(howItWorksSite.breadcrumbs.list))}
        </script>
        <script type="application/ld+json">{JSON.stringify(organizationObject())}</script>
      </Helmet>
      <Layout
        metaData={howItWorksSite.metaData}
        breadcrumbs={howItWorksSite.breadcrumbs}
        stickedForm={howItWorksSite.stickedForm}
        bannerContactInfo={undefined}
        navLangPath={howItWorksSite.langPath}
      >
        <PageComponents
          components={howItWorksSite.components}
          slug={`/${pageContext?.slug || ''}`}
        />
      </Layout>
    </>
  );
};

export default HowItWorksSite;
